<template>
  <Head :title="metaTitle">
    <meta name="description" :content="metaDescription" v-if="metaDescription" />
    <meta property="og:image" :content="ogImage || defaultImage" />
    <meta property="og:title" :content="metaTitle" />
    <meta property="og:type" :content="ogType" />
  </Head>
  <div>
    <Navbar />
    <slot name="top-content" />
    <component :is="container ? 'container' : 'main'" tag='main' v-bind='$attrs' class='min-h-screen'>
      <slot />
    </component>
    <slot name="bottom-content" />
    <Footer />
  </div>
</template>

<script lang="ts">
import Navbar from "~/components/ddf/Navbar.vue"
import Footer from "~/components/jobsdaheim/Footer.vue"
import Container from "~/elements/Container.vue"
import { Head } from "@inertiajs/inertia-vue3"

import { defineComponent, computed, watchEffect } from "vue"
import { usePage } from "@inertiajs/inertia-vue3"
import ahoy from "~/utils/ahoy"
import { logo } from '@/utils/logos'

const { props: pageProps } = usePage()
import i18n from '@/utils/i18n'

watchEffect(() => {
  if (typeof pageProps.value?.locale == "string")
    i18n.global.locale = pageProps.value.locale
})

type MetaTags = {
  title: string
  ogDescription: string
  ogType: string,
  ogImage: string
}

export default defineComponent({
  props: {
    container: { type: Boolean, default: false },
    title: { type: String, default: null },
    metaDescription: { type: String, default: null },
    ogImage: { type: String, default: null },
  },
  components: {
    Navbar,
    Footer,
    Container,
    Head,
  },
  mounted() {
    if (this.ahoy) {
      const [name, arg] = this.ahoy
      ahoy.track(name, arg)
    }
  },
  setup(props) {
    const pageMetaTags = computed(() => {
      return pageProps.value.meta_tags as MetaTags | null
    })
    const ahoy = computed(() => {
      return pageProps.value.ahoy as any
    })
    return {
      ahoy,
      pageMetaTags,
      metaDescription: computed(() => {
        return props.metaDescription || pageMetaTags.value?.ogDescription
      }),
      defaultImage: computed(() => pageMetaTags.value?.ogImage || logo),
      ogType: computed(() => pageMetaTags.value?.ogType || "website"),
      metaTitle: computed(() => {
        if (props.title) return `${props.title} | Dresdner Datenbankforen`
        if (pageMetaTags.value?.title) return `${pageMetaTags.value.title} | Dresdner Datenbankforen`
        else return `Jobs - Dresdner Datenbankforen`
      }),
    }
  },
})
</script>
