<template>
  <nav class="bg-white">
    <div class="mx-auto px-2 sm:px-6 lg:px-8">
      <div class="relative flex h-16 items-center justify-between">
        <div class="flex flex-1 items-center">
          <div class="flex flex-shrink-0 items-center text-lg font-semibold text-gray-500">
            <Link href="/" class="transition-all hover:brightness-125 hover:contrast-125">
              <img :src="logo" class="h-8 w-auto" :alt="settings.name" />
            </Link>
          </div>
          <div class="flex-grow"></div>
          <div class="mr-8 flex gap-5">
            <a href="https://www.dresdner-datenbankforum.de/" class="py-1 px-3 hover:text-gray-700">Home</a>
            <a href="/" class="bg-gray-900 px-3 py-1 text-white hover:bg-gray-700">Jobs</a>
          </div>
        </div>
        <div class="absolute inset-y-0 right-0 hidden items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 md:flex">
          <Button to="/arbeitgeber" variant="dark" size="sm" :active="isActive('/arbeitgeber')">
            {{ $t("components.navbar.anzeige_schalten") }}
          </Button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script lang="ts" setup>
import { Link } from "@inertiajs/inertia-vue3"
import { Button } from "@/elements"
import { computed } from "vue"
import { logo } from "@/utils/logos"
import settings from "@/generated/settings.json"

const slogan = settings.slogan_de

const isActive = (path: string) => {
  return window && path === window.location.pathname
}
// const activeClasses = "ring-2 ring-offset-2 ring-offset-gray-800 ring-white"
</script>
